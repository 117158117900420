import {ResponsiveType, CarouselProps} from "react-multi-carousel";
import {Config} from "react-player";

export const enum AgeGateCookieEnum {
    CookieName = 'ageCheck',
    ConsentAllValue = 'a',
    ConsentReqValue = 'r',
    CookieDuration = 3600 * 24 * 30, // 30 days, in seconds
}

/** Site default implementation of React-Multi-Carousel's `responsive` setting  */
export const CarouselResponsive: ResponsiveType = Object.freeze({
    desktop: {
        breakpoint: {
            max: 10000,
            min: 992 // Bootstrap "Large" breakpoint
        },
        items: 3,
    },
    mobile: {
        breakpoint: {
            max: 991,
            min: 0
        },
        items: 1,
    }
});

/** Site default implementation of `react-multi-carousel`'s properties. */
export const CarouselDefaultProps: Omit<CarouselProps, 'children'> = Object.freeze({
    autoPlay: true,
    autoPlaySpeed: 5_000,
    pauseOnHover: true,
    swipeable: true,
    infinite: true,
    keyBoardControl: true,
    responsive: CarouselResponsive,
    removeArrowOnDeviceType: ["desktop","mobile","tablet"],
    arrows: false
})


export const VimeoBaseUrl = 'https://player.vimeo.com/video/';
export const VimeoConfig = (title?: string): Config => {
    return {
        vimeo: {
            title,
            playerOptions: {
                background: false,
                portrait: false,
                byline: false
            },
        }
    }
};



export const enum FullPageClasses {
    Section = 'fp-section',
    Slide = 'fp-slide',
    AutoHeight = 'fp-auto-height', // For sections that aren't entire pages in size (like the footer)
}

export const enum NavbarOptions {
    Transparent = '',
    Opaque = 'opaque'
}
